import React from 'react';
import {
  AboutSection,
  HighlighText,
  Heading,
  SubHeading,
  Title,
  ImgHolder,
  AboutLayout,
  TextLayout,
  AboutHeading,
  Description,
} from './about.style';
import { Col, Row, Container } from '@bootstrap-styled/v4';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from 'gatsby-image';
import CustomBackgroundImage from '../../components/CustomBackgroundImage';

const AboutPage = () => {
  const JSONData = useStaticQuery(graphql`
    query {
      pagedataJson {
        About {
          AboutImgAdam {
            childImageSharp {
              fluid(quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          AboutImgLevente {
            childImageSharp {
              fluid(quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          AboutImgAnna {
            childImageSharp {
              fluid(quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          AboutImgLaszlo {
            childImageSharp {
              fluid(quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          Img {
            childImageSharp {
              fluid(quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          AboutHeading
        }
      }
    }
  `);

  const renderAboutAdam = () => (
    <AboutLayout>
      <TextLayout>
        <Heading>{'Gálos Ádám'}</Heading>
        <Title>dobtanár, iskolavezető</Title>
        <Description>
          7 éves koromban a bátyám megfogta a kezem és beíratott a soproni zeneiskolába dobolni.
          Valahol ez időtájt dőlhetett el végérvényesen, a zene nem csak hobby lesz számomra. Az
          általános és középiskola alatt a zeneiskola mellett a Hoffer Zenesuliban szereztem biztos
          tudást, illetve lehetőségem volt Borlai Gergőtől is magánórákat venni.
        </Description>
        <Description>
          Érettségi után 2010-ig a Kőbányai Zenei Stúdióban tanultam, ahol emelt szintű szakirányú
          zenei végzettséget szereztem és a magyar zenei élet olyan nagyjaitól tanulhattam, mint
          Póka Egon, Babos Gyula, Tóth János Rudolf, Sramkó János, Solti János, stb...
        </Description>
        <Description>
          Eddigi zenei karrierem során a következő produkciókban fordultam meg: Kistehén, Padödö,
          Biorobot, Hiperkarma, Freddie...
        </Description>

        <Description>
          A szélesebb közönség a 2010-ben alakult
          <HighlighText> Intim Torna Illegál </HighlighText>
          dobosaként, egyik alapítójaként ismer.
        </Description>

        <Description>
          2019-ben
          <a
            href="https://petofilive.hu/petofizeneidij/cikk/2020/07/07/meg-most-is-az-egyik-legmeghatarozobb-pillanat-galos-adam-intim-torna-illegal/#"
            target="_blank"
            rel="noreferrer noopener"
            style={{ textDecoration: 'none' }}
          >
            <HighlighText> Petőfi Díjat </HighlighText>
          </a>
          vehettem át „Az év ütőhangszerese" kategóriában.
        </Description>

        <Description>
          Tempó, dinamika, koordináció, függetlenítés, stílusgyakorlatok, technikai gyakorlatok,
          ritmusgyakorlatok, kottaolvasás... Az óráimon ilyen és ezekhez hasonló témakörökkel
          foglalkozunk. Várlak, emeljük új szintre a tudásodat!
        </Description>
      </TextLayout>
    </AboutLayout>
  );

  const renderAboutLevente = () => (
    <AboutLayout>
      <Heading>{'Boros Levente'}</Heading>
      <Title>dobtanár</Title>

      <TextLayout>
        <Description>
          16 éves korom óta dobolok, de a zene már kisiskolásként is kitöltötte az életem.
          Zenetagozatos általánosba jártam, kórusokban énekeltem, 6 évig zongoráztam.
        </Description>
        <Description>
          A kamaszkori próbatermi gyakorlások és zenekaros próbálkozások után a Kőbányai Zenei
          Stúdióban kezdtem igazán elmélyülni a dobolásban. Tanulhattam Jávori Vili bácsitól, Solti
          Jánostól, Sramkó Jánostól és Németh Gábortól.
        </Description>
        <Description>
          A kőbányais évek alatt indult be a könnyűzenei karrierem. A hagyományosabb rock, funk és
          egyéb groove zenei projektek mellett volt pár tartalmas évem a kísérleti / free jazz
          szcénában is. Abban az időben nagyon sok új hangot, hangzást fedeztem fel a
          dobfelszerelésen, amit beépítve a játékomba azóta is alkalmazok. Kedvenc terepem a jazz /
          funk alapú hip-hop dobolás.
        </Description>
        <Description>
          A velem töltött dobórákon az alapvető technikai és stílusgyakorlatok mellett hangsúlyt kap
          a dobszerkón belüli hangszerelés felfedezése, a végtagok közötti balansz megteremtése
          különböző dinamikákon, valamint a minimalista dobolás kifejező erejének megismertetése.
        </Description>
        <Description>
          Fő zenekaraim a the Qualitons és az Amoeba. Mindkét együttes fonogram díjas. A the
          Qualitons-szal első magyar zenekarként felléphettünk a seattle-i KEXP rádió stúdiójában.
        </Description>
      </TextLayout>
    </AboutLayout>
  );

  const renderAboutAnna = () => (
    <AboutLayout>
      <TextLayout>
        <Heading>{'Hertelendi Anna'}</Heading>
        <Title>dobtanár</Title>
        <Description>
          3 éves korom óta dobolok, 6 éves koromtól zeneiskolában tanultam, mellette mindig jártam
          magántanárokhoz is.
        </Description>
        <Description>
          Középiskola után a Kőbányai Zenei Stúdióban tanultam 2021-ig. Jelenleg a Póka Egon
          Művészeti Akadémián folytatom tanulmányaimat.
        </Description>
        <Description>
          Dobóráimon alapvető technikai gyakorlatok mellett fontos számomra a kottaolvasás,
          különböző stílusokba való kitekintés és a sok zenére játszás.
        </Description>

        <Description>
          Eddigi zenekaraim, ahol találkozhattál velem: Ladánybene 27, Copy Con, anyámkínja
        </Description>
      </TextLayout>
    </AboutLayout>
  );

  const renderAboutLaszlo = () => (
    <AboutLayout>
      <TextLayout>
        <Heading>{'Ferencz László'}</Heading>
        <Title>dobtanár</Title>
        <Description>
          {
            '13 éves lehettem, amikor rájöttem, hogy dobolni szeretnék, köszönhetően egy gyerekkori barátomnak aki gitározott, és az akkori MTV-nek, amin sok jó zene ment. :)'
          }
        </Description>
        <Description>
          Középiskolás éveim alatt a nyíregyházi Vikár Sándor Zeneiskolában kezdtem el dobot
          tanulni, majd a debreceni Rocksuliban folytattam. A hangszer iránti szeretetem egyre csak
          nőtt, így jelentkeztem a Kőbányai Zenei Stúdióba, ahová sikeresen felvettek. Itt olyan
          kiváló zenészektől tanulhattam, mint Sramkó János, Németh Gábor, Solti János, Babos Gyula
          és Fekete-Kovács Kornél. Később Szanyi János magántanítványa lettem, akinek szintén sokat
          köszönhetek.
        </Description>
        <Description>
          {
            'Imádom a ‘60-as és ‘70-es évek funk és soul zenéit, valamint a hiphop és r&b műfajokat. Hiszem, hogy a kevesebb néha több.'
          }
        </Description>

        <Description>
          Néhány nagyszerű zenekar, amelyekben láthatsz engem: Flanger Kids, Noira, Perrin, Szabó
          Balázs Bandája, Szesztay Dávid zenekara.
        </Description>
      </TextLayout>
    </AboutLayout>
  );

  return (
    <CustomBackgroundImage fluidImage={JSONData.pagedataJson.About.Img.childImageSharp.fluid}>
      <AboutSection id="aboutContainer">
        <Container style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px' }}>
          <SubHeading>Bemutatkozunk</SubHeading>
          <AboutHeading>Tanáraink</AboutHeading>

          <div>
            <Row>
              <Col lg="6" md="12">
                <ImgHolder>
                  <GatsImg
                    style={{ maxWidth: '28rem' }}
                    fluid={JSONData.pagedataJson.About.AboutImgAdam.childImageSharp.fluid}
                    alt="Galos Adam profilkép"
                    className="aboutImg"
                  />
                </ImgHolder>
                {renderAboutAdam()}
              </Col>

              <Col lg="6" md="12">
                <ImgHolder>
                  <GatsImg
                    fluid={JSONData.pagedataJson.About.AboutImgLevente.childImageSharp.fluid}
                    alt="Boros Levente profilkép"
                    className="aboutImg"
                  />
                </ImgHolder>
                {renderAboutLevente()}
              </Col>

              <Col lg="6" md="12">
                <ImgHolder>
                  <GatsImg
                    fluid={JSONData.pagedataJson.About.AboutImgAnna.childImageSharp.fluid}
                    alt="Hertelendi Anna profilkép"
                    className="aboutImg"
                  />
                </ImgHolder>
                {renderAboutAnna()}
              </Col>

              <Col lg="6" md="12">
                <ImgHolder>
                  <GatsImg
                    fluid={JSONData.pagedataJson.About.AboutImgLaszlo.childImageSharp.fluid}
                    alt="Ferenczi Laszlo profilkép"
                    className="aboutImg"
                  />
                </ImgHolder>
                {renderAboutLaszlo()}
              </Col>
            </Row>
          </div>
        </Container>
      </AboutSection>
    </CustomBackgroundImage>
  );
};

export default AboutPage;
