import styled from 'styled-components';
import { device } from '../Common/device';
import { SectionHeading } from '../Common/common.style';
// import { Row, Col } from 'react-bootstrap';
import { Row, Col } from '@bootstrap-styled/v4';

export const GallerySection = styled.section`
  padding: 100px 0px 94px;
  background: #000;

  @media ${device.tablet} {
    padding: 80px 10px 74px;
  }
`;

export const Heading = styled(SectionHeading)`
  margin-bottom: 75px;

  @media ${device.tablet} {
    margin-bottom: 60px;
  }
`;

export const HeadingSpan = styled.span`
  color: white;
`;

export const CustomRow = styled(Row)`
  margin-left: -3px;
  margin-right: -3px;
`;

export const CustomCol = styled(Col)`
  padding-left: 3px;
  padding-right: 3px;
`;

export const GallerySliderCol = styled.div``;

export const GalleryImageHolder = styled.figure`
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 6px;

  .GallerySliderImg {
    height: 250px;
    backface-visibility: hidden;
    -webkit-transform: scale(1);
    transform: scale(1);
    transition: transform 0.3s ease-in-out !important;
    -webkit-transition: -webkit-transform 0.3s ease-in-out !important;

    :hover {
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
    }

    @media ${device.tablet} {
      width: 100%;
    }
  }
`;
