import styled from 'styled-components';
import { device } from '../Common/device';
import { Commonh5, Commonpara, SectionHeading } from '../Common/common.style';
import { QuoteLeft } from 'styled-icons/fa-solid/QuoteLeft';
import { QuoteRight } from 'styled-icons/fa-solid/QuoteRight';
import { ChevronLeft } from 'styled-icons/fa-solid/ChevronLeft';
import { ChevronRight } from 'styled-icons/fa-solid/ChevronRight';

export const TestimonialWrapper = styled.section`
  padding: 100px 0px 60px;
  background: #fafafa;

  @media ${device.tablet} {
    padding: 80px 10px 40px;
  }
`;

export const HeadingLayout = styled.div`
  margin-bottom: 35px;

  @media ${device.tablet} {
    margin-bottom: 20px;
  }
`;

export const TestimonialHeading = styled(SectionHeading)``;

export const SliderOuterWrapper = styled.div`
  max-width: 800px;
  margin: 0px auto;
  position: relative;
  .slick-list {
    line-height: 0;
  }
`;

export const SliderWrapper = styled.div`
  outline: 0;
`;

export const TestimonialCard = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0px 0px 30px 0px #ddd;
  margin: 40px;

  @media ${device.laptop} {
    flex-direction: column;
  }
`;

export const ImageLayout = styled.div`
  width: 250px;
  flex-shrink: 0;

  @media ${device.laptop} {
    width: 100%;
  }

  .authorImg {
    width: 250px;
    height: 350px;
    object-fit: cover;

    @media ${device.laptop} {
      width: 100%;
    }
  }
`;

export const TextLayout = styled.div`
  padding: 30px;
`;

export const StyledIconOpen = styled(QuoteLeft)`
  height: 40px;
  width: 40px;
  opacity: 0.3;
`;

export const TestimonialText = styled(Commonpara)`
  margin-bottom: 0px;
  padding: 0px 50px;

  @media ${device.tablet} {
    padding: 20px 0px;
  }
`;
export const QuoteHolder = styled.div`
  text-align: right;
`;

export const StyleIconClose = styled(QuoteRight)`
  height: 40px;
  width: 40px;
  opacity: 0.3;
`;

export const Author = styled(Commonh5)`
  text-align: left;
  margin-bottom: 0px;
  padding: 0px 50px;
  margin-bottom: 5px;

  @media ${device.tablet} {
    padding: 0px;
  }
`;

export const Designation = styled(Commonpara)`
  padding: 0px 50px;
  margin-bottom: 0px;
  @media ${device.tablet} {
    padding: 0px;
  }
`;

export const ImgButtonLeft = styled.button`
  line-height: 0px;
  padding-bottom: 0px;
  border: none;
  background: none;
  outline: none;
  position: absolute;
  top: calc(50% - 15px);
  z-index: 99;
  background: #2bb1a0;
  padding: 10px 15px;
  left: 15px;
`;

export const LeftIcon = styled(ChevronLeft)`
  width: 20px;
  height: 20px;
  color: #fff;
`;

export const ImgButtonRight = styled.button`
  line-height: 0px;
  padding-bottom: 0px;
  border: none;
  background: none;
  outline: none;
  position: absolute;
  top: calc(50% - 15px);
  z-index: 99;
  background: #2bb1a0;
  padding: 10px 15px;
  right: 15px;
`;

export const RightIcon = styled(ChevronRight)`
  width: 20px;
  height: 20px;
  color: #fff;
`;
