import React from 'react';
import { ImageBackground } from './CustomBackgroundImage.style';

const CustomBackgroundImage = ({ children, fluidImage }) => (
  <ImageBackground Tag="section" fluid={fluidImage} fadeIn="soft">
    {children}
  </ImageBackground>
);

export default CustomBackgroundImage;
