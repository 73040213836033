import React from 'react';
import {
  ImageTextSection,
  CustomRow,
  TextLayout,
  Heading,
  Description1,
  ImageHolder,
} from './imagetext3.style';
import { Container, Col } from '@bootstrap-styled/v4';
import GatsImg from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

const ImageText3 = () => {
  const JSONData = useStaticQuery(graphql`
    query {
      pagedataJson {
        ImageText3 {
          Heading
          Description1
          ListText1
          ListText2
          ListText3
          ListImage1
          ListImage2
          ListImage3
          ContactUsBtn
          Img {
            childImageSharp {
              fluid(quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  return (
    <ImageTextSection id="imagetext3Container">
      <Container>
        <CustomRow>
          <Col md={12} lg={8}>
            <TextLayout>
              <Heading>Várunk szeretettel...</Heading>
              <Description1>
                ...ha szeretnél jó hangulatú légkörben közelebbről is megismerkedni a dobolás
                világával, vagy eddigi tudásodat szeretnéd új szintre emelni.
              </Description1>
            </TextLayout>
          </Col>
          <Col md={12} lg={4}>
            <ImageHolder>
              <GatsImg fluid={JSONData.pagedataJson.ImageText3.Img.childImageSharp.fluid} alt="" />
            </ImageHolder>
          </Col>
        </CustomRow>
      </Container>
    </ImageTextSection>
  );
};

export default ImageText3;
