import styled from 'styled-components';
import { device } from '../Common/device';
import { Commonh5, Commonpara, SectionHeading } from '../Common/common.style';
import { School } from '@styled-icons/material/School';
import { EmojiEmotions } from '@styled-icons/material/EmojiEmotions';
import { EmojiPeople } from '@styled-icons/material/EmojiPeople';

import { CameraVideoFill } from '@styled-icons/bootstrap/CameraVideoFill';

export const FeaturesSection = styled.section`
  background-color: rgba(0, 0, 0, 0.5);
  padding: 100px 0px 70px;

  @media ${device.tablet} {
    padding: 80px 10px 50px;
  }
`;

export const FeaturesHeading = styled(SectionHeading)`
  color: #fff;
`;

export const HeadingLayout = styled.div`
  margin-bottom: 75px;

  @media ${device.tablet} {
    margin-bottom: 60px;
  }
`;

export const FeaturesSingle = styled.div`
  text-align: center;
  padding: 10px;
`;

export const IconContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.4);
  display: inline-block;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
`;

export const FeatureIcon = styled(EmojiEmotions)`
  color: #fff;
  width: 50px;
  height: 50px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;
export const FeatureIcon2 = styled(School)`
  color: #fff;
  width: 50px;
  height: 50px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;
export const FeatureIcon3 = styled(EmojiPeople)`
  color: #fff;
  width: 50px;
  height: 50px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;
export const FeatureIcon4 = styled(CameraVideoFill)`
  color: #fff;
  width: 40px;
  height: 40px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;

export const Heading = styled(Commonh5)`
  color: #fff;
`;

export const FeaturesDesc = styled(Commonpara)`
  color: #fff;
  text-align: center;
`;
