import React from 'react';
import {
  IconContainer,
  FeatureIcon,
  FeatureIcon2,
  FeatureIcon3,
  FeatureIcon4,
  FeaturesSection,
  FeaturesSingle,
  Heading,
  FeaturesDesc,
} from './features.style';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import { useStaticQuery, graphql } from 'gatsby';
import CustomBackgroundImage from '../../components/CustomBackgroundImage';

const Features = () => {
  const JSONData = useStaticQuery(graphql`
    query {
      pagedataJson {
        Features {
          Img {
            childImageSharp {
              fluid(quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          FeaturesImg1
          FeaturesHeading1
          FeaturesDesc1
          FeaturesImg2
          FeaturesHeading2
          FeaturesDesc2
          FeaturesImg3
          FeaturesHeading3
          FeaturesDesc3
          FeaturesImg4
          FeaturesHeading4
          FeaturesDesc4
        }
      }
    }
  `);
  return (
    <CustomBackgroundImage fluidImage={JSONData.pagedataJson.Features.Img.childImageSharp.fluid}>
      <FeaturesSection id="featuresContainer">
        <Container>
          <Row>
            <Col lg={3} md={6}>
              <FeaturesSingle>
                <IconContainer>
                  <FeatureIcon />
                </IconContainer>
                <Heading>{JSONData.pagedataJson.Features.FeaturesHeading1}</Heading>
                <FeaturesDesc>{JSONData.pagedataJson.Features.FeaturesDesc1}</FeaturesDesc>
              </FeaturesSingle>
            </Col>
            <Col lg={3} md={6}>
              <FeaturesSingle>
                <IconContainer>
                  <FeatureIcon2 />
                </IconContainer>
                <Heading>{JSONData.pagedataJson.Features.FeaturesHeading2}</Heading>
                <FeaturesDesc>{JSONData.pagedataJson.Features.FeaturesDesc2}</FeaturesDesc>
              </FeaturesSingle>
            </Col>
            <Col lg={3} md={6}>
              <FeaturesSingle>
                <IconContainer>
                  <FeatureIcon3 />
                </IconContainer>
                <Heading>{JSONData.pagedataJson.Features.FeaturesHeading3}</Heading>
                <FeaturesDesc>{JSONData.pagedataJson.Features.FeaturesDesc3}</FeaturesDesc>
              </FeaturesSingle>
            </Col>
            <Col lg={3} md={6}>
              <FeaturesSingle>
                <IconContainer>
                  <FeatureIcon4 />
                </IconContainer>
                {/* <FeaturesImg src={JSONData.pagedataJson.Features.FeaturesImg4} alt="" /> */}
                <Heading>{JSONData.pagedataJson.Features.FeaturesHeading4}</Heading>
                <FeaturesDesc>{JSONData.pagedataJson.Features.FeaturesDesc4}</FeaturesDesc>
              </FeaturesSingle>
            </Col>
          </Row>
        </Container>
      </FeaturesSection>
    </CustomBackgroundImage>
  );
};

export default Features;
