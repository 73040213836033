import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

export const ImageBackground = styled(BackgroundImage)`
  background-size: cover;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;

  /* override the default margin for sibling elements  */
  + * {
    margin-top: 0;
  }
`;
