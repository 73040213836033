import React, { Component } from 'react';
import {
  ContactWrapper,
  IconContainer,
  ContactIcon,
  EmailIcon,
  AddressIcon,
  ContactTitle,
  ContactContent,
  ContactBox,
} from './contact.style';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import { StaticQuery, graphql } from 'gatsby';
import CustomBackgroundImage from '../../components/CustomBackgroundImage';
class Contact extends Component {
  render() {
    return (
      <CustomBackgroundImage
        fluidImage={this.props.pagedataJson.Contact.BackgroundImg.childImageSharp.fluid}
      >
        <ContactWrapper id="contactContainer">
          <Container>
            <Row style={{ justifyContent: 'center' }}>
              <Col lg={4} md={6}>
                <a
                  href="https://maps.app.goo.gl/XEvrZrZAadzy2MiX9"
                  aria-label={'cím'}
                  target="_blank"
                  rel="noreferrer noopener"
                  style={{ textDecoration: 'none' }}
                >
                  <ContactBox>
                    <IconContainer>
                      <AddressIcon />
                    </IconContainer>
                    <ContactTitle>CÍM</ContactTitle>
                    <ContactContent>{'1113 Budapest, Edömér utca 4.'}</ContactContent>
                  </ContactBox>
                </a>
              </Col>
              <Col lg={4} md={6}>
                <a href="tel:+36703645384" style={{ textDecoration: 'none' }}>
                  <ContactBox>
                    <IconContainer>
                      <ContactIcon />
                    </IconContainer>
                    <ContactTitle>TELEFON</ContactTitle>
                    <ContactContent>{'+36 70 364 53 84'}</ContactContent>
                  </ContactBox>
                </a>
              </Col>
              <Col lg={4} md={6}>
                <a href="mailto:info@doboktatas.hu" style={{ textDecoration: 'none' }}>
                  <ContactBox>
                    <IconContainer>
                      <EmailIcon />
                    </IconContainer>
                    <ContactTitle>EMAIL</ContactTitle>
                    <ContactContent>{'info@doboktatas.hu'}</ContactContent>
                  </ContactBox>
                </a>
              </Col>
            </Row>
          </Container>
        </ContactWrapper>
      </CustomBackgroundImage>
    );
  }
}
export default () => (
  <StaticQuery
    query={graphql`
      query {
        pagedataJson {
          Contact {
            BackgroundImg {
              childImageSharp {
                fluid(quality: 70) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            ContactFormHeading
          }
        }
      }
    `}
    render={(data) => <Contact pagedataJson={data.pagedataJson} />}
  />
);
