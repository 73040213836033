import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import '../components/layout.css';

import { SocialFacebook } from 'styled-icons/typicons/SocialFacebook';
import { SocialInstagram } from 'styled-icons/typicons/SocialInstagram';
import { SocialYoutube } from 'styled-icons/typicons/SocialYoutube';
import styled from 'styled-components';

import { device } from '../containers/Common/device';
import SEO from '../components/seo';
import Banner from '../containers/Banner';
import Features from '../containers/Features';
import OurStory from '../containers/OurStory';
import ImageText1 from '../containers/ImageText1';
import ImageText2 from '../containers/ImageText2';
import ImageText3 from '../containers/ImageText3';
import About from '../containers/About';
import Testimonials from '../containers/Testimonials';
import Contact from '../containers/Contact';
import HeaderMenu from '../containers/HeaderMenu';
import Footer from '../containers/Footer';
import GlobalStyle from '../containers/Common/global-styles';
import Fonts from '../containers/Common/fonts';
import Faq from '../containers/Faq';
import Gallery from '../containers/Gallery';

const SocialIconBar = styled.div`
  position: fixed;
  display: 'flex';
  background: rgba(125, 28, 195, 70%);
  bottom: 0;
  right: 2%;
  border-radius: 10px;
  z-index: 1;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  @media ${device.laptopM} {
  }
  @media ${device.laptopM} {
  }
  @media ${device.tablet} {
  }
`;

const FbIcon = styled(SocialFacebook)`
  width: 32px;
  color: #fff;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  padding: 6px;
  margin: 5px 5px;
  transition: all 0.5s;
  :hover {
    background: #3b5999;
    color: #fff;
  }
`;
const InstaIcon = styled(SocialInstagram)`
  width: 32px;
  color: #fff;
  background: rgba(255, 255, 255, 0.3);
  padding: 6px;
  border-radius: 100%;
  margin: 5px 5px;
  transition: all 0.5s;
  :hover {
    background: #ed008d;
    color: #fff;
  }
`;
const YoutubeIcon = styled(SocialYoutube)`
  width: 32px;
  color: #fff;
  background: rgba(255, 255, 255, 0.3);
  padding: 6px;
  border-radius: 100%;
  margin: 5px 5px;
  transition: all 0.5s;
  :hover {
    background: #ff0100;
    color: #fff;
  }
`;

const LinkSocial = styled.a`
  ${'' /* margin-right: 10px; */}
`;

export default () => {
  const [isOpenLightBox, setLightBoxOpen] = useState(false);
  const [lightBoxImages, setLightBoxImages] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);

  const openLightBox = (imageArray, idx) => {
    const images = [];
    for (const [index, value] of imageArray.Slider.entries()) {
      console.log(index);
      images.push(value.GallerySliderImg.childImageSharp.fluid.src);
    }
    setLightBoxOpen(true);
    setLightBoxImages(images);
    setPhotoIndex(idx);
  };

  return (
    <>
      <div>
        <Fonts />
        <GlobalStyle />
        <HeaderMenu />
        <main>
          <SEO />
          <Banner />
          <OurStory />
          <About />
          <Features />
          <ImageText1 />
          <ImageText2 />
          <ImageText3 />
          <Gallery openLightBox={openLightBox} />
          <Testimonials />
          <Faq />
          <Contact />
          <SocialIconBar>
            <LinkSocial
              href="https://www.facebook.com/galosadamdobiskola"
              aria-label={'Facebook Link'}
              target="_blank"
              rel="noreferrer noopener"
            >
              <FbIcon />
            </LinkSocial>
            <LinkSocial
              href="https://www.instagram.com/galosadamdobiskola/"
              aria-label={'Instagram Link'}
              target="_blank"
              rel="noreferrer noopener"
            >
              <InstaIcon />
            </LinkSocial>
            <LinkSocial
              href="https://www.youtube.com/channel/UCUTtJJvPJRpAREW5CLxkw5g"
              aria-label={'Youtube Link'}
              target="_blank"
              rel="noreferrer noopener"
            >
              <YoutubeIcon />
            </LinkSocial>
          </SocialIconBar>
        </main>
        <Footer />
      </div>
      {isOpenLightBox && (
        <Lightbox
          mainSrc={lightBoxImages[photoIndex]}
          nextSrc={lightBoxImages[(photoIndex + 1) % lightBoxImages.length]}
          prevSrc={lightBoxImages[(photoIndex + lightBoxImages.length - 1) % lightBoxImages.length]}
          onCloseRequest={() => setLightBoxOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + lightBoxImages.length - 1) % lightBoxImages.length)
          }
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % lightBoxImages.length)}
        />
      )}
    </>
  );
};
