import React, { Component } from 'react';
import {
  Headerwrapper,
  NavCustom,
  UlCustom,
  LiCustomLogo,
  BrandLogo,
  LiCustomItem,
  LiCustomToggle,
  AnchorCustom,
  MenuIcon,
} from './headermenu.style';
import { Container } from '@bootstrap-styled/v4';
import ScrollSpy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { StaticQuery, graphql } from 'gatsby';
class Headermenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stickyClass: 'top',
      isActive: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', () => {
      let stickyClass = 'topSticky';
      let scrollPos = window.scrollY;

      if (scrollPos < 100) {
        stickyClass = 'top';
      }

      if (this.state.stickyClass !== stickyClass) {
        this.setState({ stickyClass });
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll');
  }

  menuClick = () => {
    this.setState({
      isActive: !this.state.isActive,
    });
  };

  render() {
    return (
      <Headerwrapper isSticky={this.state.stickyClass === 'topSticky' ? true : false}>
        <NavCustom isSticky={this.state.stickyClass === 'topSticky' ? true : false}>
          <Container>
            <UlCustom>
              <LiCustomLogo isSticky={this.state.stickyClass === 'topSticky' ? true : false}>
                <ScrollSpy offset={-59}>
                  <AnchorLink offset={55} href={'#homeContainer'}>
                    <BrandLogo
                      src={
                        this.state.stickyClass === 'topSticky'
                          ? this.props.pagedataJson.Header.BrandLogoSticky
                          : this.props.pagedataJson.Header.BrandLogo
                      }
                      isSticky={this.state.stickyClass === 'topSticky' ? true : false}
                      alt="Logo"
                    />
                  </AnchorLink>
                </ScrollSpy>
              </LiCustomLogo>

              <LiCustomItem
                className={this.state.isActive ? 'active-main-li' : ''}
                isSticky={this.state.stickyClass === 'topSticky' ? true : false}
              >
                <ScrollSpy
                  offset={-59}
                  items={this.props.pagedataJson.Header.Items}
                  currentClassName="is-current"
                >
                  {this.props.pagedataJson.Header.ScrollSpy.map((item, idx) => {
                    return (
                      <li className={this.state.isActive ? 'active' : ''} key={`iem-${idx}`}>
                        <AnchorLink offset={55} href={item.Href} onClick={this.menuClick}>
                          {item.Menu}
                        </AnchorLink>
                      </li>
                    );
                  })}
                </ScrollSpy>
              </LiCustomItem>

              <LiCustomToggle>
                <AnchorCustom onClick={this.menuClick}>
                  <MenuIcon isSticky={this.state.stickyClass === 'topSticky' ? true : false} />
                </AnchorCustom>
              </LiCustomToggle>
            </UlCustom>
          </Container>
        </NavCustom>
      </Headerwrapper>
    );
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        pagedataJson {
          Header {
            BrandLogo
            BrandLogoSticky
            Items
            ScrollSpy {
              Menu
              Href
            }
          }
        }
      }
    `}
    render={(data) => <Headermenu pagedataJson={data.pagedataJson} />}
  />
);
