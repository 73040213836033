import React from 'react';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import { FaqSection, FaqHeadingWrapper, FaqSectionHeading, FaqAccordianWrapper } from './faq.style';
import AccordianCustom from './AccordianCustom';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';

const Faq = () => {
  const JSONData = useStaticQuery(graphql`
    query {
      pagedataJson {
        FAQ {
          DelayConstant
          FaqSectionHeading
          AccordianCustom {
            title
            description
          }
        }
      }
    }
  `);
  return (
    <FaqSection id="faqContainer">
      <Container>
        <FaqHeadingWrapper>
          <Row>
            <Col md="12">
              <Fade bottom delay={1 * JSONData.pagedataJson.FAQ.DelayConstant}>
                <FaqSectionHeading>{JSONData.pagedataJson.FAQ.FaqSectionHeading}</FaqSectionHeading>
              </Fade>
            </Col>
          </Row>
        </FaqHeadingWrapper>
        <Fade bottom delay={1 * JSONData.pagedataJson.FAQ.DelayConstant}>
          <FaqAccordianWrapper>
            <Row>
              <Col md="12">
                {JSONData.pagedataJson.FAQ.AccordianCustom.map((item, idx) => {
                  return (
                    <AccordianCustom
                      title={item.title}
                      description={item.description}
                      key={`accordian-${idx}`}
                    />
                  );
                })}
              </Col>
            </Row>
          </FaqAccordianWrapper>
        </Fade>
      </Container>
    </FaqSection>
  );
};
export default Faq;
