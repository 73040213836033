import styled from 'styled-components';
import { device } from '../Common/device';
import { Commonh4, Commonpara } from '../Common/common.style';
import { Row } from '@bootstrap-styled/v4';

export const ImageTextSection = styled.section`
  padding: 50px 100px 0px;

  @media ${device.tablet} {
    padding: 80px 10px 40px;
  }
`;

export const CustomRow = styled(Row)`
  align-items: center;
`;

export const TextLayout = styled.div`
  @media ${device.laptop} {
    margin-bottom: 30px;
  }
`;

export const Heading = styled(Commonh4)`
  margin-bottom: 50px;
  text-align: left;
  text-transform: uppercase;
`;

export const Description1 = styled(Commonpara)`
  text-align: justify;
`;

export const Description2 = styled(Commonpara)`
  text-align: justify;
`;

export const ImageHolder = styled.div`
  max-width: 400px;
  padding-left: 50px;
  margin: auto;

  @media ${device.laptop} {
    padding-left: 0px;
  }
`;

export const HighlighText = styled.span`
  font-weight: bold;
  color: #2bb1a0;
`;
