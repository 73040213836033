import React, { Component } from 'react';
import _ from 'lodash';

import {
  GallerySection,
  Heading,
  HeadingSpan,
  CustomRow,
  CustomCol,
  GalleryImageHolder,
} from './gallery.style';

import { Container } from '@bootstrap-styled/v4';
import { StaticQuery, graphql } from 'gatsby';
import GatsImg from 'gatsby-image';

const firstRowPictures = [
  {
    id: 0,
    alt: 'Galos Ádám - Petőfi díj',
  },
  {
    id: 1,
    alt: 'Galos Adam Budapest park',
  },
  {
    id: 2,
    alt: 'Galos Adam 1',
  },
];

const secondRowPictures = [
  {
    id: 3,
    alt: 'Galos Adam 2',
  },
  {
    id: 4,
    alt: 'Galos Adam 3',
  },
  {
    id: 5,
    alt: 'Boros Levente 1',
  },
];

const thirdRowPictures = [
  {
    id: 6,
    alt: 'Boros Levente 2',
  },

  {
    id: 7,
    alt: 'Boros Levente 3',
  },
  {
    id: 8,
    alt: 'Boros Levente 4',
  },
];

class Gallery extends Component {
  openDialogBox(imageArray, idx) {
    this.props.openLightBox(imageArray, idx);
  }

  render() {
    return (
      <GallerySection id="gallerySection">
        <Container>
          <Heading>
            <HeadingSpan>{this.props.GalleryData.HeadingSpan}</HeadingSpan>
          </Heading>

          <CustomRow>
            {_.map(firstRowPictures, ({ id, alt }) => (
              <CustomCol md={4}>
                <GalleryImageHolder
                  onClick={this.openDialogBox.bind(this, this.props.GalleryData, id)}
                >
                  <GatsImg
                    fluid={this.props.GalleryData.Slider[id].GallerySliderImg.childImageSharp.fluid}
                    className="GallerySliderImg"
                    alt={alt}
                  />
                </GalleryImageHolder>
              </CustomCol>
            ))}
          </CustomRow>

          <CustomRow>
            {_.map(secondRowPictures, ({ id, alt }) => (
              <CustomCol md={4}>
                <GalleryImageHolder
                  onClick={this.openDialogBox.bind(this, this.props.GalleryData, id)}
                >
                  <GatsImg
                    fluid={this.props.GalleryData.Slider[id].GallerySliderImg.childImageSharp.fluid}
                    className="GallerySliderImg"
                    alt={alt}
                  />
                </GalleryImageHolder>
              </CustomCol>
            ))}
          </CustomRow>

          <CustomRow>
            {_.map(thirdRowPictures, ({ id, alt }) => (
              <CustomCol md={4}>
                <GalleryImageHolder
                  onClick={this.openDialogBox.bind(this, this.props.GalleryData, id)}
                >
                  <GatsImg
                    fluid={this.props.GalleryData.Slider[id].GallerySliderImg.childImageSharp.fluid}
                    className="GallerySliderImg"
                    alt={alt}
                  />
                </GalleryImageHolder>
              </CustomCol>
            ))}
          </CustomRow>
        </Container>
      </GallerySection>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        pagedataJson {
          Gallery {
            Heading
            HeadingSpan
            Slider {
              GallerySliderImg {
                childImageSharp {
                  fluid(quality: 70) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <Gallery GalleryData={data.pagedataJson.Gallery} {...props} />}
  />
);
