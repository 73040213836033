import styled from 'styled-components';
import { device } from '../Common/device';
import { Commonh4, Commonpara } from '../Common/common.style';
import { Row } from '@bootstrap-styled/v4';

export const OurStorySection = styled.section`
  padding: 100px;

  @media ${device.tablet} {
    padding: 40px 10px 40px;
  }
`;

export const CustomRow = styled(Row)`
  align-items: center;
  @media ${device.laptop} {
    flex-direction: column-reverse;
  }
`;

export const TextLayout = styled.div`
  padding-left: 20px;

  @media ${device.laptop} {
    margin-bottom: 30px;
  }

  @media ${device.tablet} {
    padding-left: 0px;
  }
`;

export const Heading = styled(Commonh4)`
  margin-bottom: 50px;
  text-align: left;
  text-transform: uppercase;
`;

export const Description1 = styled(Commonpara)`
  text-align: justify;
`;

export const Description2 = styled(Commonpara)`
  text-align: justify;
`;

export const ImageHolder = styled.div`
  max-width: 400px;
  padding: 10px;
  margin: auto;
  position: 'relative';

  @media ${device.laptop} {
    padding: 50px 30px;
  }
`;

export const HighlighText = styled.span`
  font-weight: bold;
  color: #2bb1a0;
`;
