import styled from 'styled-components';
import { device } from '../Common/device';
import { Commonpara, Commonh3, Commonh4, Commonh6 } from '../Common/common.style';
import { Row } from '@bootstrap-styled/v4';

export const AboutSection = styled.section`
  background-color: rgba(255, 255, 255, 0.8);
  padding: 80px 0px 80px;

  @media ${device.laptop} {
    background-position-x: 35%;
  }
  @media ${device.tablet} {
    padding: 80px 10px 60px;
  }
`;

export const Heading = styled(Commonh4)`
  text-align: center;
`;

export const SubHeading = styled(Commonh6)`
  text-align: center;

  text-transform: uppercase;
`;

export const Title = styled(Commonpara)`
  text-align: center;

  text-transform: uppercase;
`;

export const HighlighText = styled.span`
  font-weight: bold;
  color: #2bb1a0;
`;

export const CustomRow = styled(Row)`
  padding: 20px;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
`;

export const ImgHolder = styled.div`
  padding: 40px 100px;
  border-radius: 200px!;
`;

export const AboutLayout = styled.div`
  padding: 20px 20px;
`;

export const TextLayout = styled.div``;

export const AboutHeading = styled(Commonh3)`
  color: #2bb1a0;
  text-align: center;
  line-height: 0.5;

  @media ${device.tablet} {
    text-align: center;
  }
`;

export const Description = styled(Commonpara)`
  text-align: justify;
  color: #000000;
`;
