import styled from 'styled-components';
import { device } from '../Common/device';
import { Call } from '@styled-icons/material/Call';
import { Email } from '@styled-icons/material/Email';
import { LocationOn } from '@styled-icons/material/LocationOn';

export const ContactWrapper = styled.section`
  padding: 100px 0px 80px;

  background-color: rgba(25, 7, 38, 0.8);

  @media ${device.tablet} {
    padding: 80px 10px 80px;
  }
`;

export const IconContainer = styled.div`
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
`;

export const ContactIcon = styled(Call)`
  color: #fff;
  width: 40px;
  height: 40px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;
export const AddressIcon = styled(LocationOn)`
  color: #fff;
  width: 40px;
  height: 40px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;
export const EmailIcon = styled(Email)`
  color: #fff;
  width: 40px;
  height: 40px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;

export const ContactBox = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  display: 'flex';
  justify-content: center;
  text-align: center;
  margin: 0.5rem;
  padding: 1.5rem;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  :hover {
    transition: all 0.3s ease-in-out;
    transform: scale(1.03);
    ${'' /* background-color: rgba(87, 21, 137, 0.5); */}
    background-color: rgb(20 165 158 / 50%);
    cursor: pointer;
  }
  ${'' /* flex-basis: calc(33.3333333333% - 2 * .5rem);
    flex-grow: 1;
    max-width: 100%; */}
`;

export const ContactContent = styled.div`
  color: white;
`;
export const ContactTitle = styled.div`
  font-weight: 700;
  font-size: 1.08rem;
  text-transform: uppercase;
  line-height: 2;
  letter-spacing: 0px;
  color: white;
`;
