import styled from 'styled-components';
import { device } from '../Common/device';
import { Commonpara } from '../Common/common.style';
import { Row } from '@bootstrap-styled/v4';
import { RightArrow } from 'styled-icons/boxicons-regular/RightArrow';

export const ImageTextSection = styled.section`
  padding: 50px 100px 0px;

  @media ${device.tablet} {
    padding: 40px 10px 40px;
  }
`;

export const CustomRow = styled(Row)`
  align-items: center;
  @media ${device.laptop} {
    flex-direction: column-reverse;
  }
`;

export const TextLayout = styled.div`
  @media ${device.laptop} {
    margin-bottom: 30px;
  }
`;

export const Description1 = styled(Commonpara)`
  text-align: justify;
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
`;

export const ListItem = styled.li`
  display: flex;
  margin-bottom: 10px;
`;

export const RightArrowIcon = styled(RightArrow)`
  color: #2bb1a0;
  height: 20px;
  width: 20px;
  flex-shrink: 0;
  margin-top: 5px;
`;

export const ListText = styled(Commonpara)`
  line-height: 1;
  margin-bottom: 0;
  margin-left: 20px;
  line-height: 25px;
`;

export const ImageHolder = styled.div`
  max-width: 400px;
  padding-right: 50px;
  margin: auto;

  @media ${device.laptop} {
    padding-right: 0px;
  }
`;
