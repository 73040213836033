import styled from 'styled-components';
import { device } from '../Common/device';
import { Commonbtn, Commonh1, Commonh6 } from '../Common/common.style';
import { PlayCircle } from 'styled-icons/boxicons-regular/PlayCircle';

export const BannerSection = styled.section`
  ${'' /* background-color: rgba(0, 0, 0, 0.5); */}

  min-height: 100vh;
  padding: 160px 0px 100px;
  display: flex;
  align-items: center;

  @media ${device.laptopM} {
    background-position-x: 70%;
  }
  @media ${device.laptopM} {
    background-position-x: 90%;
  }
  @media ${device.tablet} {
    background-position-x: 90%;
    padding: 140px 10px 80px;
  }
`;

export const BannerContents = styled.div`
  display: flex;
  align-items: center;
`;

export const BannerContentsLeft = styled.div`
  /* width: 80%; */
  width: 100%;

  /* @media ${device.laptop} {
    width: 65%;
  }
  @media ${device.tablet} {
    width: 100%;
  } */
`;

export const BannerHeading = styled(Commonh1)`
  color: #fff;
`;

export const BannerDescription = styled(Commonh6)`
  color: #fff;
  opacity: 0.9;
  max-width: 300px;

  @media ${device.tablet} {
    margin: 0 auto 20px;
  }
`;

export const ButtonLayout = styled.div`
  display: flex;
  align-items: center;

  @media ${device.tablet} {
    justify-content: center;
  }
  @media ${device.mobileL} {
    flex-direction: column;
  }
`;

export const CallNowBtn = styled(Commonbtn)``;

export const WatchVideoLayout = styled.div`
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  margin-left: 20px;
  background: #14a59eab;
  padding: 7px 30px;
  border-radius: 5px;

  :hover {
    transition: all 0.3s ease-in-out;
    transform: scale(1.05);
    background: #14a59e;
  }

  @media ${device.mobileXL} {
    padding: 5px 20px;
  }

  @media ${device.mobileL} {
    margin-top: 20px;
    margin-left: 0px;
  }
`;

export const PlayIcon = styled(PlayCircle)`
  width: 28px;
  height: 28px;
  color: #fff;
`;

export const PlayText = styled.div`
  display: inline-block;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  margin-left: 5px;
`;
