import React from 'react';
import {
  ImageTextSection,
  CustomRow,
  TextLayout,
  Description1,
  List,
  ListItem,
  RightArrowIcon,
  ListText,
  ImageHolder,
} from './imagetext2.style';
import { Container, Col } from '@bootstrap-styled/v4';
import GatsImg from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

const ImageText2 = () => {
  const JSONData = useStaticQuery(graphql`
    query {
      pagedataJson {
        ImageText2 {
          Heading
          Description1
          Description2
          ListText1
          ListText2
          ListText3
          ContactUsBtn
          Img {
            childImageSharp {
              fluid(quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  return (
    <ImageTextSection id="imagetext2Container">
      <Container>
        <CustomRow>
          <Col md={12} lg={4}>
            <ImageHolder>
              <GatsImg fluid={JSONData.pagedataJson.ImageText2.Img.childImageSharp.fluid} alt="" />
            </ImageHolder>
          </Col>
          <Col md={12} lg={8}>
            <TextLayout>
              {/* <Heading>{JSONData.pagedataJson.ImageText2.Heading}</Heading> */}
              <Description1>{JSONData.pagedataJson.ImageText2.Description1}</Description1>
              <Description1>Az órákon a következő témakörökkel foglalkozunk:</Description1>
              <List>
                <ListItem>
                  <RightArrowIcon />
                  <ListText>ritmusgyakorlatok</ListText>
                </ListItem>
                <ListItem>
                  <RightArrowIcon />
                  <ListText>technikai gyakorlatok</ListText>
                </ListItem>
                <ListItem>
                  <RightArrowIcon />
                  <ListText>kottaolvasás</ListText>
                </ListItem>
                <ListItem>
                  <RightArrowIcon />
                  <ListText>stílusgyakorlatok (rock, funk, fusion, latin, jazz)</ListText>
                </ListItem>
              </List>

              {/* <ContactUsBtn>{JSONData.pagedataJson.ImageText2.ContactUsBtn}</ContactUsBtn> */}
            </TextLayout>
          </Col>
        </CustomRow>
      </Container>
    </ImageTextSection>
  );
};

export default ImageText2;
