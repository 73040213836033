import React from 'react';
import {
  BannerSection,
  BannerContents,
  BannerContentsLeft,
  BannerHeading,
  BannerDescription,
  ButtonLayout,
  CallNowBtn,
  WatchVideoLayout,
  PlayText,
} from './banner.style';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Container } from '@bootstrap-styled/v4';
import { useStaticQuery, graphql } from 'gatsby';
import CustomBackgroundImage from '../../components/CustomBackgroundImage';

const Banner = () => {
  const JSONData = useStaticQuery(graphql`
    query {
      pagedataJson {
        Banner {
          BannerHeading
          BannerDescription
          WatchVideoAnchor
          BtnText1
          BtnText2
          Img {
            childImageSharp {
              fluid(quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  return (
    <CustomBackgroundImage
      fluidImage={JSONData.pagedataJson.Banner.Img.childImageSharp.fluid}
      style={{ filter: 'grayscale(100%)' }}
    >
      <BannerSection id="homeContainer">
        <Container>
          <BannerContents>
            <BannerContentsLeft>
              <BannerHeading>{JSONData.pagedataJson.Banner.BannerHeading}</BannerHeading>
              <BannerDescription>
                {JSONData.pagedataJson.Banner.BannerDescription}
              </BannerDescription>
              <ButtonLayout>
                <AnchorLink offset={55} href={'#imagetext1Container'}>
                  <CallNowBtn>{JSONData.pagedataJson.Banner.BtnText1}</CallNowBtn>
                </AnchorLink>
                <WatchVideoLayout>
                  <AnchorLink offset={55} href={'#contactContainer'}>
                    <PlayText>{JSONData.pagedataJson.Banner.BtnText2}</PlayText>
                  </AnchorLink>
                </WatchVideoLayout>
              </ButtonLayout>
            </BannerContentsLeft>
          </BannerContents>
        </Container>
      </BannerSection>
    </CustomBackgroundImage>
  );
};

export default Banner;
