import React from 'react';
import {
  OurStorySection,
  CustomRow,
  TextLayout,
  Heading,
  Description1,
  ImageHolder,
} from './OurStory.style';
import { Container, Col } from '@bootstrap-styled/v4';
// import GatsImg from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

const OurStory = () => {
  const JSONData = useStaticQuery(graphql`
    query {
      pagedataJson {
        OurStory {
          Img {
            childImageSharp {
              fluid(quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  return (
    <OurStorySection id="OurStoryContainer">
      <Container>
        <CustomRow>
          <Col md={12} lg={6}>
            <ImageHolder>
              <div style={{ position: 'relative', top: 0, left: 0 }}>
                <img
                  id="MySecondImage"
                  src={JSONData.pagedataJson.OurStory.Img.childImageSharp.fluid.src}
                  alt="Our Story drum class 1"
                  style={{
                    borderRadius: '5%',
                    opacity: 0.1,
                    position: 'absolute',
                    top: '30px',
                    left: '30px',
                  }}
                />
                <img
                  src={JSONData.pagedataJson.OurStory.Img.childImageSharp.fluid.src}
                  alt="Our Story drum class 2"
                  style={{
                    borderRadius: '5%',
                    position: 'relative',
                    top: '-30px',
                    left: '-30px',
                  }}
                />
              </div>
            </ImageHolder>
          </Col>
          <Col md={12} lg={6}>
            <TextLayout>
              <Heading>Történetünk röviden</Heading>
              <Description1>
                Többévnyi egyéni oktatás után 2020-ban hoztam létre saját dobiskolámat azzal a
                céllal, hogy karrierem során szerzett eddigi tapasztalataimat átadjam a dobolni
                vágyóknak. Alap célkitűzésem egy olyan iskola megteremtése volt, ahol a lehető
                legmodernebb eszközök segítségével a diákok korszerű, használható, zene központú
                tudást szerezhessenek, valamint az órákon felszabadultan, a hétköznapi terhektől
                mentesen, könnyedén, élményszerűen tanulhassanak.
              </Description1>
              <Description1>
                2022 szeptemberétől a nagy érdeklődésre való tekintettel iskolánk új tanárral, Boros
                Leventével bővült.
              </Description1>
            </TextLayout>
          </Col>
        </CustomRow>
      </Container>
    </OurStorySection>
  );
};

export default OurStory;
