import React from 'react';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import {
  FooterSection,
  FooterInner,
  BottomFooterPara,
  BottomFooterWrapper,
  BottomLink,
} from './footer.style';
import { useStaticQuery, graphql } from 'gatsby';

const Footer = () => {
  const JSONData = useStaticQuery(graphql`
    query {
      pagedataJson {
        Footer {
          DelayConstant
          FooterCol1 {
            FooterLogo
            FooterPara
          }
          FooterCol2 {
            FooterTitle
            FooterPara
          }
          FooterCol3 {
            FooterTitle
            FooterUl {
              FooterMenu
            }
          }
          FooterCol4 {
            FooterTitle
            FooterUl {
              FooterMenu
              Href
            }
          }
          BottomFooterPara
          BottomFooterName
          BottomFooterLink
        }
      }
    }
  `);
  return (
    <div>
      <FooterSection>
        <FooterInner>
          <Container>
            {/* <Row style={{ justifyContent: 'center' }}>
              <Col>
                <FooterCol>
                  <FooterSocial href="#" aria-label={'Fb Link'}>
                    <FbIcon />
                  </FooterSocial>

                  <FooterSocial href="#" aria-label={'Instagram Link'}>
                    <InstaIcon />
                  </FooterSocial>
                </FooterCol>
              </Col>
            </Row> */}
            <BottomFooterWrapper>
              <Row>
                <Col md="12">
                  <BottomFooterPara>
                    {JSONData.pagedataJson.Footer.BottomFooterPara}
                    <BottomLink
                      rel="noreferrer"
                      href={JSONData.pagedataJson.Footer.BottomFooterLink}
                      // target="_blank"
                    >
                      {JSONData.pagedataJson.Footer.BottomFooterName}
                    </BottomLink>
                  </BottomFooterPara>
                </Col>
              </Row>
            </BottomFooterWrapper>
          </Container>
        </FooterInner>
      </FooterSection>
    </div>
  );
};

export default Footer;
